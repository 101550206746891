import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout"

export default function Aanmelden({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Instagram" location={location}>
      <div className="page">
        <Heading>Instagram</Heading>
        <div style={{ margin: "1rem 0 " }}>
          Volg ons ook op{" "}
          <a href="https://www.instagram.com/fairsy24/">Instagram Fairsy</a> of
          ontdek ons op <Link to="/facebook/">facebook</Link> waar je ons per
          provincie kunt volgen!
        </div>
      </div>
    </Layout>
  )
}
